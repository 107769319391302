import React from "react";
import styles from './Button.module.scss';
import {Link} from "react-router-dom";

interface Props {
    children: string;
    to?: string;
}

export const Button = ({children, to}: Props) => (
    to
        ?
        <Link className={styles.button} to={to}>{children}</Link>
        :
        <button className={styles.button}>{children}</button>
)
