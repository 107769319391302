import React, {useState} from 'react';
import {Map} from "./components/layouts/Map/Map";
import {Header} from "./components/layouts/Header/Header";
import { SearchContext } from './contexts/search.context';
import {Route, Routes} from "react-router-dom";
import { AdForm } from './components/AdForm/AdForm';

export const App = () => {
    const [search, setSearch] = useState('');

  return (
    <SearchContext.Provider value={{search, setSearch}}>
        <Header />
        <Routes>
            <Route path="/" element={<Map />}/>
            <Route path="/add" element={<AdForm />}/>
        </Routes>
    </SearchContext.Provider>
  );
}
