import React, {useEffect, useState} from "react";
import { AdEntity } from "types";
import {apiUrl} from "../../../config/api";
// import styles from './SingleAd.module.scss';

interface Props {
    id: string
}

export const SingleAd = ({ id }: Props) => {
    const [ad, setAd] = useState<AdEntity | null>(null);
    useEffect(() => {
        (async () => {
            const res = await fetch(`${apiUrl}/ad/${id}`);
            const ad = await res.json();

            setAd(ad);
        })();
    },[]);

    if (ad === null) {
        return <p>Wczytywanie...</p>
    }

    return (
        <>
            <h2>Nazwa: {ad.name}</h2>
            <p>Opis: {ad.description}</p>
            {!!ad.price && <p>Cena: <b>{ad.price} zł</b></p>}
            <hr/>
            <a href={ad.url} target="_blank" rel="noreferrer">Otwórz ogloszenie</a>
        </>
    )
}
