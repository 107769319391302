import React, {SyntheticEvent, useContext, useState} from "react";
import {Button} from "../../common/Button/Button";
import styles from './Header.module.scss'
import {SearchContext} from "../../../contexts/search.context";

export const Header = () => {
    const {search, setSearch} = useContext(SearchContext);
    const [inputVal, setInputVal] = useState(search);

    const setSearchFromLocalState = (e: SyntheticEvent) => {
        e.preventDefault();
        setSearch(inputVal);
    };

    return (
        <header className={styles.header}>
            <h1>
                <strong>Mega</strong> Ogłoszenie
            </h1>
            <Button to="/add">Dodaj ogłoszenie</Button>
            <form className="search" onSubmit={setSearchFromLocalState}>
                <input type="text" value={inputVal} onChange={e => setInputVal(e.target.value)}/>
                <Button>Szukaj</Button>
            </form>
        </header>
    )
}
